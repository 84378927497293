import React from 'react';
import {Link} from 'gatsby';

const Header = () => (
  <header className="ui borderless fixed menu">
    <div className="ui text container">
      <Link to="/" className="header item arial-black text--large">
        noy72.com
      </Link>
      <Link to="/tags" className="item">
        <i
          className="small tags icon"
          style={{
            paddingTop: '1px',
          }}
        />
        タグ一覧
      </Link>
      <Link to="/privacy" className="item">
        <i
          className="small user icon"
          style={{
            paddingTop: '1px',
          }}
        />
        プライバシー
      </Link>
    </div>
  </header>
);

export default Header;
