import React from 'react';
import Layout from '../templates/layout';

const _404 = () => (
  <Layout>
    <div className="ui divided items">
      <div className="item">
        <div className="image">
          <span
            role="img"
            aria-label="thinking-face"
            style={{
              fontSize: '15rem',
            }}
          >
            🤔
          </span>
        </div>
        <div
          className="middle aligned content arial-black"
          style={{
            fontSize: '3.5rem',
          }}
        >
          This is not the web page you are looking for.
        </div>
      </div>
    </div>
  </Layout>
);

export default _404;
