import React from 'react';
import Header from '../components/Header';
import 'fomantic-ui-css/semantic.min.css';
import '../styles/style.scss';

const Layout = ({children}: {children: JSX.Element[] | JSX.Element}) => (
  <>
    <Header />
    <main
      className="ui text container"
      style={{
        paddingTop: '5rem',
        paddingBottom: '7rem',
      }}
    >
      {children}
    </main>
  </>
);

export default Layout;
